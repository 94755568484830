<template>
  <div class="documentNotes">
    <div class="documentNotes__title border-bottom d-flex align-items-center">
      <div class="pr-1">
        <i class="el-icon-info fs-4"></i>
      </div>
      <div>
        <h4 class="mb-0 text-dark">Document Information</h4>
        <p class="notes-text text-muted">Created, Shared, Recipients Status</p>
      </div>
    </div>
    <div class="documentNotes_body">
      <el-scrollbar>
        <div class="tracking-status">
          <h4 class="text-dark">Current Status</h4>

          <p class="notes-text text-muted capitalize">
            {{ getTrackingStatus }}
          </p>
          <el-divider></el-divider>
          <!-- Progress -->
          <h4 class="text-dark">Document Status</h4>
          <div class="document-status">
            <div
              class="box d-flex"
              v-for="(user, index) in getDocumentUsersInfo"
              :key="index"
            >
              <div class="status-icon me-2">
                <i :class="user.icon"></i>
              </div>
              <div class="content w-100">
                <h4 class="mb-0">
                  {{ user.name }} 
                  <p
                    class="float-right"
                    style="font-weight: normal; font-size: 14px;"
                  >
                    {{ user.time  |  globalDateFormat }}
                    <br />
                    {{ user.time | globalTimeFormat }}
                  </p>
                </h4>
                <p :class="user.textStyle">{{ user.status }}</p>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmpDoc-DocumentInfo",
  props: [
    "document",
    "update_document_data",
    "is_current_user",
    "is_document_completed",
  ],
  data() {
    return {
      documentNotesEntry: {
        notesTxt: "",
        declined_user: "",
      },
      documentInfoInterver: null,
    };
  },
  computed: {
    getTrackingStatus() {
      
      return this.document &&
        this.document.document_tracking_status &&
        this.document.document_tracking_status.description
        ? this.document.document_tracking_status.description
        : " ";
    },

    getDeclinedReason() {
      let reason = "";
      if (this.documentUsers) {
        let declinedUser = this.documentUsers.find((user) => {
          return user.declined_status == true;
        });
        if (declinedUser) {
          reason = declinedUser.declined_notes;
        }
      }
      return reason;
    },
    getDocumentUsersInfo() {
      let mySignatureDataExist = false
      
      
      if(this.document &&
      this.document.configurable_document_data && 
      this.document.configurable_document_data.pages &&
      this.document.configurable_document_data.pages[0] &&
      this.document.configurable_document_data.pages[0].fields
      //key: "my_signature"
      ){
        this.document.configurable_document_data.pages[0].fields.find((el)=>{
          if(el && el.key && el.key == 'my_signature'){
            mySignatureDataExist = true
          }
        })
      }
      let users = [];
      if (this.document && this.document.document_users) {
        this.document.document_users.forEach((user) => {

          switch (true) {
            case this.document.document_status == "VOIDED" &&
              user.type == "SENDER": {
              let st = this.document.document_status.toLowerCase();
              let st2 = st.charAt(0).toUpperCase() + st.slice(1);
              let docFailUser = {
                name: user.first_name + " " + user.last_name,
                status: st2,
                time:
                  user.document_filling_date ||
                  user.approval_date ||
                  user.sent_on ||
                  user.declined_at,
                icon: "el-icon-circle-close not-sent",
                textStyle: "text-danger",
              };
              users.push(docFailUser);
              break;
            }
             case user.approval_status && user.document_filling_status: {
              let approverUser = {
                name:user.first_name+ " " + user.last_name,
                status: "Approved",
                time:
                  user.document_filling_date ||
                  user.approval_date ||
                  user.sent_on,
              icon: "el-icon-check success",
                textStyle: "text-success",
              };
              users.push(approverUser);
              break;
            }
            case user.document_filling_status && (user.e_signature_required == true || user.e_signature_required == 'true' ||  mySignatureDataExist == true)  : {
              let completedUser = {
                name: user.first_name + " " + user.last_name,
                status: "Signed",
                time:
                  user.declined_at ||
                  user.document_filling_date ||
                  user.approval_date ||
                  user.sent_on,
                icon: "el-icon-check success",
                textStyle: "text-success",
              };
              users.push(completedUser);
              break;
            }
            case user.document_filling_status && (user.e_signature_required == false || user.e_signature_required == 'false' || mySignatureDataExist == false ): {
              let completedUser = {
                name: user.first_name + " " + user.last_name,
                status: "Completed",
                time:
                  user.declined_at ||
                  user.document_filling_date ||
                  user.approval_date ||
                  user.sent_on,
                icon: "el-icon-check success",
                textStyle: "text-success",
              };
              users.push(completedUser);
              break;
            }
            
            case user.user_type == "CC": {
              let completedUser = {
                name: user.first_name + " " + user.last_name,
                status: "Non signer",
                time:
                  user.document_filling_date ||
                  user.approval_date ||
                  user.sent_on ||
                  user.declined_at,
                icon: "el-icon-check success",
                textStyle: "text-success",
              };
              users.push(completedUser);
              break;
            }
            case user.declined_status || user.approval_status == "REJECTED": {
              let completedUser = {
                name: user.first_name + " " + user.last_name,
                status: "Declined",
                time: user.declined_at || user.sent_on,
                icon: "el-icon-circle-close in-progress",
                textStyle: "text-danger",
              };
              users.push(completedUser);
              break;
            }
            case !user.document_filling_status && user.sent_status: {
              let inProcessUser = {
                name: user.first_name + " " + user.last_name,
                status: "In-progress",
                time:
                  user.document_filling_date ||
                  user.approval_date ||
                  user.sent_on ||
                  user.declined_at,
                icon: "el-icon-refresh in-progress",
                textStyle: "text-fail",
              };
              users.push(inProcessUser);
              break;
            }
            default: {
              let notSentUser = {
                name: user.first_name + " " + user.last_name,
                status: "Not yet sent",
                time: null,
                icon: "el-icon-time not-sent",
                textStyle: "text-primary",
              };
              users.push(notSentUser);
            }
          }
          // if (user.document_filling_status) {
          //   let completedUser = {
          //     name: user.first_name + " " + user.last_name,
          //     status: "Completed",
          //     time:
          //       user.declined_at ||
          //       user.document_filling_date ||
          //       user.approval_date ||
          //       user.sent_on,
          //     icon: "el-icon-check success",
          //     textStyle: "text-success",
          //   };
          //   users.push(completedUser);
          // } else if (user.declined_status) {
          //   let completedUser = {
          //     name: user.first_name + " " + user.last_name,
          //     status: "Declined",
          //     time: user.declined_at || user.sent_on,
          //     icon: "el-icon-circle-close success",
          //     textStyle: "text-danger",
          //   };
          //   users.push(completedUser);
          // } else if (
          //   (user.fields_required || user.has_approval_access) &&
          //   !user.document_filling_status &&
          //   user.sent_status &&
          //   this.document.document_status == "DECLINED"
          // ) {
          //   let inProcessUser = {
          //     name: user.first_name + " " + user.last_name,
          //     status: `Declined by User`,

          //     time:
          //       user.document_filling_date ||
          //       user.approval_date ||
          //       user.sent_on ||
          //       user.declined_at,
          //     icon: "el-icon-refresh in-progress",
          //     textStyle: "text-fail",
          //   };
          //   users.push(inProcessUser);
          // } else if (
          //   user.user_type == "CC"
          // ) {
          //   let completedUser = {
          //     name: user.first_name + " " + user.last_name,
          //     status: "Non signer",
          //     time:
          //       user.document_filling_date ||
          //       user.approval_date ||
          //       user.sent_on ||
          //       user.declined_at,
          //     icon: "el-icon-check success",
          //     textStyle: "text-success",
          //   };
          //   users.push(completedUser);
          // } else if (
          //   (user.fields_required || user.has_approval_access) &&
          //   !user.document_filling_status &&
          //   user.sent_status &&
          //   !(
          //     this.document.document_status == "EXPIRED" ||
          //     this.document.document_status == "VOIDED" ||
          //     this.document.document_status == "DECLINED"
          //   )
          // ) {
          //   let inProcessUser = {
          //     name: user.first_name + " " + user.last_name,
          //     status: "In-progress",
          //     time:
          //       user.document_filling_date ||
          //       user.approval_date ||
          //       user.sent_on ||
          //       user.declined_at,
          //     icon: "el-icon-refresh in-progress",
          //     textStyle: "text-fail",
          //   };
          //   users.push(inProcessUser);
          // } else if (
          //   (user.fields_required || user.has_approval_access) &&
          //   !user.document_filling_status &&
          //   user.sent_status &&
          //   (this.document.document_status == "EXPIRED" ||
          //     this.document.document_status == "VOIDED" ||
          //     this.document.document_status == "DECLINED")
          // ) {
          //   let st = this.document.document_status.toLowerCase();
          //   let st2 = st.charAt(0).toUpperCase() + st.slice(1);
          //   let docFailUser = {
          //     name: user.first_name + " " + user.last_name,
          //     status: st2,
          //     time:
          //       user.document_filling_date ||
          //       user.approval_date ||
          //       user.sent_on ||
          //       user.declined_at,
          //     icon: "el-icon-circle-close not-sent",
          //     textStyle: "text-danger",
          //   };
          //   users.push(docFailUser);
          // } else if (!user.document_filling_status && !user.sent_status) {
          //   let notSentUser = {
          //     name: user.first_name + " " + user.last_name,
          //     status: "Not yet sent",
          //     time: null,
          //     icon: "el-icon-time not-sent",
          //     textStyle: "text-primary",
          //   };
          //   users.push(notSentUser);
          // }
        });
      }
      return users;
    },
  },
  async mounted() {
    // if (!this.is_current_user && !this.is_document_completed) {
    //   this.documentInfoInterver = setInterval(this.getDocumentInfoInterval, 10000);
    // }
  },
  methods: {
    handleCommand(command) {
      this.$message("click on item " + command);
    },
    async getDocumentInfoInterval() {
      if (this.is_current_user || this.is_document_completed) {
        clearInterval(this.documentInfoInterver);
        return;
      }

      var el = document.getElementById("tab-0");

      try {
        if (
          el.classList.contains("el-tabs__item") &&
          el.classList.contains("is-active")
        ) {
          await this.update_document_data();
          // this.$emit('update_document_data');
        }
      } catch (err) {
        console.log("getDocumentInfoInterval() called, err", err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.documentNotes {
  .documentNotes__title {
    padding: 10px 15px;
  }
  .documentNotes_body {
    .notes {
      display: grid;
      grid-template-columns: 50px 1fr;
      padding: 12px 7px 0 7px;
      &:last-child {
        padding-bottom: 12px;
      }
      .notes-avatar {
        .avatar-text {
          height: 40px;
          width: 40px;
          font-weight: 600;
          font-size: 1.1em;
          line-height: 40px;
          text-align: center;
          border-radius: 30px;
          background-color: #ffffff;
        }
      }
      .notes-content {
        position: relative;
        h5 {
          font-size: 1.05em;
          color: #444444;
          font-weight: 600;
          line-height: 18px;
          margin-bottom: 0.15em;
          .postTime {
            font-size: 12px;
            font-weight: 400;
            color: #aaaaaa;
          }
        }
        .notes-text {
          font-size: 1em;
          line-height: 1.5;
          word-break: break-word;
        }
      }
      .color-1 {
        border: 1.5px solid #f2994a;
        color: #f2994a;
      }
      .color-2 {
        border: 1.5px solid #2d9cdb;
        color: #2d9cdb;
      }

      .color-3 {
        border: 1.5px solid #bb6bd9;
        color: #bb6bd9;
      }
      .color-4 {
        border: 1.5px solid #27ae60;
        color: #27ae60;
      }
      .color-5 {
        border: 1.5px solid #1f22ec;
        color: #1f22ec;
      }
      .color-6 {
        border: 1.5px solid #1d6648;
        color: #1d6648;
      }
      .color-7 {
        border: 1.5px solid #e20215;
        color: #e20215;
      }
      .color-8 {
        border: 1.5px solid #ff008c;
        color: #ff008c;
      }
    }
  }
  .documentNotes_entry {
  }
  .tracking-status {
    padding: 1em;
  }
}
</style>
<style lang="scss">
.el-dropdown-menu {
  padding: 0;
  &:hover,
  &:focus {
    outline: none;
  }
}
.el-dropdown-menu__item {
  padding: 0 15px;
  line-height: 30px;
}
.documentNotes {
  .documentNotes_body {
    .el-scrollbar__wrap {
      overflow-y: auto;
      max-height: calc(100vh - 274px);
    }
    .notess-option {
      position: absolute;
      right: 0px;
      top: 0px;
      line-height: 1;
      padding: 2px;
      cursor: pointer;
      color: #666666;
      &:hover,
      &:focus {
        color: #181818;
      }
    }
    .el-icon-arrow-down {
      font-size: 12px;
    }
    .el-scrollbar {
      .el-scrollbar__wrap {
        overflow-y: auto;
        max-height: calc(100vh - 270px);
      }
    }
  }
  .documentNotes_entry {
    // position: relative;
    // height: 100px;
    .inline-entry-field {
      // position: absolute;
      // top: 0px;
      // left: 0;
      // right: 0;
      margin: 2px 2px 0;
    }
    .sendNote__Btn {
      position: absolute;
      bottom: 0;
      right: 5px;
      z-index: 100;
    }
  }
}
.document-status {
  .box {
    position: relative;
    &:last-child {
      &:after {
        display: none;
      }
    }
    &::after {
      content: "";
      position: absolute;
      top: 20px;
      left: 9px;
      width: 1px;
      height: 50px;
      border-left: 2px dashed #bbbbbb;
    }
  }
  .status-icon {
    position: relative;
    z-index: 10;
    i {
      color: #ffffff;
      height: 20px;
      width: 20px;
      text-align: center;
      line-height: 20px;
      border-radius: 10px;
    }
    .success {
      background-color: #48968a;
    }
    .in-progress {
      background-color: #f15178;
    }
    .not-sent {
      background-color: #121212;
    }
    .void {
      background-color: #a321af;
    }
  }
}
</style>